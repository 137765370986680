body {
  margin: 0;
  font-family: Arial, sans-serif;
}

html,
body,
#root {
  height: 100%;
}

.bg-gray-900 {
  background-color: #ed1629;
}

.bg-gray-800 {
  background-color: #ed1629;
}

.text-blue-600 {
  color: #ed1629;
}

.hover\:underline:hover {
  text-decoration: underline;
}

.transition-colors {
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}

main {
  padding: 0 20px;
}

.bg-white {
  margin: 0 auto; 
  border-radius: 10px; 
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
  min-height: calc(98vh - 100px); 
  padding: 20px;
  max-width: 1900px; 
  margin-bottom: 20px; 
}
